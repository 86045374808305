.animated-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 10px 36px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    overflow: hidden;
    border-radius: 100px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    background: transparent;
  
    /* Gradient Border */
    position: relative;
    border: 2px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
  }
  
  .animated-button::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 4px;
    border-radius: 100px;
    background: linear-gradient(135deg, #00768B, #0E2DA7); /* Gradient Border */
    -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
  }
  
  .animated-button svg {
    position: absolute;
    width: 24px;
    fill: white;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .arr-1 {
    right: 16px;
  }
  
  .animated-button .arr-2 {
    left: -25%;
  }
  
  .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background: linear-gradient(135deg, #00768B, #0E2DA7);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: white;
    border-radius: 12px;
  }
  
  .animated-button:hover .arr-1 {
    right: -25%;
  }
  
  .animated-button:hover .arr-2 {
    left: 16px;
  }
  
  .animated-button:hover .text {
    transform: translateX(12px);
  }
  
  .animated-button:hover svg {
    fill: white;
  }
  
  .animated-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px white;
  }
  
  .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  