
.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}


.ball_bounce {
    animation: 1.5s bouncing-ball infinite ease-in-out;
}

.ball_bounce_z {
    animation: 1.5s bouncing-ball-z infinite ease-in-out;
}


@keyframes bouncing-ball {

    0%,
    100% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(25px);
    }
}


@keyframes bouncing-ball-z {

    0%,
    100% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(25px, 25px);
    }
}

.scrollable-container {
    max-height: 235px;
    overflow-x: auto;
    padding-right: 12px;
}

.scrollable-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
    background: #0000007a;
    border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #3a3a3a;
    border-radius: 10px;
}

.scrollable-container2 {
    max-height: 300px;
    overflow-x: auto;
    padding-right: 40px;
}

.scrollable-container2::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scrollable-container2::-webkit-scrollbar-track {
    background: #0000007a;
    border-radius: 10px;
}

.scrollable-container2::-webkit-scrollbar-thumb {
    background: #3a3a3a;
    border-radius: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.rotate-gradient {
    animation: spin 4s linear infinite;
    animation-timing-function: linear;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    color: white; 
    filter: brightness(0) invert(1);
}



.button {
    position: relative;
    padding: 7px 40px;
    background: linear-gradient(135deg, #00a4b0, #496dc9);
    font-size: 17px;
    font-weight: 500;
    color: white;
    /* border: 3px solid #00a4b0; */
    border-radius: 8px;
    box-shadow: 0 0 0 #00a4b08c;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .star-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  
  .star-2 {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.8s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-5 {
    position: absolute;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.6s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-6 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.8s ease;
  }
  
  .button:hover {
    background: transparent;
    color: #00a4b0;
    box-shadow: 0 0 25px #00a4b08c;
  }
  
  .button:hover .star-1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  .button:hover .star-2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  .button:hover .star-3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .button:hover .star-4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .button:hover .star-5 {
    position: absolute;
    top: 25%;
    left: 115%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .button:hover .star-6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .fil0 {
    fill: #fffdef;
  }
  